<!-- 电能集抄 -->
<template>
  <div id="electricity-collect">
    <!-- <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="false"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div> -->
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>电能集抄</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="开始时间" class="long-label">
              <el-date-picker type="datetime" :append-to-body="false" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择开始时间" v-model="formData.staTime"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间"  class="long-label">
              <el-date-picker type="datetime" :append-to-body="false" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择结束时间" v-model="formData.endTime"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="queryOperation" type="primary" icon="el-icon-search">搜索</el-button>
              <!-- <el-button type="primary" icon="el-icon-download">导出</el-button> -->
            </el-form-item>
          </el-form>
        </div>

        <el-table class="table" :data="tableData">
          <el-table-column align="center" prop="loopName" label="回路名称">
            <template slot-scope="scope">
              {{ scope.row.loopName }}  ({{ scope.row.loopNumber }})
            </template>
          </el-table-column>
          <el-table-column align="center" prop="dianNengMin" label="起始数值">
          </el-table-column>
          <el-table-column align="center" prop="dianNengMax" label="截止数值">
          </el-table-column>
          <el-table-column align="center" prop="diffN"  label="差值">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
export default {
  name: "",

  data() {
    return {
      formData: {
        staTime: "",
        endTime:'',
      },
      tabsData: ["日报", "月报", "年报"],
      tableData: [],
      select: 0,
     searchNumber:'',  
    };
  },
  created(){
    this.$store.commit('increment', '用电分析')
    this.$store.commit('selectChild', '电能集抄')
    this.$store.commit("selectChildren", "");
},

  mounted() {

    this.stationNumber = sessionStorage.getItem('stationNumber')
    this.queryOperation();
  },

  methods: {
    //
    search(){
      console.log(this.formData);
    },
   
    switchTabs(index) {
      this.select = index;
    },
   
    queryOperation() {
      const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要加 1；使用 padStart 补齐两位
    const day = String(today.getDate()).padStart(2, '0'); // 使用 padStart 补齐两位

      let obj={
        stationNumber:this.stationNumber,
        staTime:this.formData.staTime,
        endTime:this.formData.endTime,
      }
      if( !this.formData.staTime){
        obj.staTime =  `${year}-${month}-${day} 00:00:00`
      }
      if(!this.formData.endTime){
        obj.endTime =  `${year}-${month}-${day} 23:59:59` 
      }
      emissionsCarbonApi.getCollectList(obj).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
        }
      });
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#electricity-collect {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  width: 100%;
  min-width: 1280px;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 0 20px 0;
}

.query-form :deep() .el-form {
  height: 40px;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}

/* .form :deep().el-date-editor {
  width: 200px;
} */

.hour :deep() .el-select {
  width: 56px;
  padding: 0;
}

.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
  width: 100%;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041c4c;
}
::v-deep .is-plain {
  background: #176ad2;
  border: 0;
  color: #fff;
}
::v-deep .el-table__body-wrapper {
  height: 70vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
